
// Include any default variable overrides here (though functions won't be available)
$body-bg: rgb(27, 27, 27);
$body-color:white;
$primary:rgb(0, 199, 255);

$font-family-sans-serif:'Poppins', sans-serif;


@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here
.navbar-collapse {
    justify-content: flex-end;
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.0rem;
        padding-left: 0.5rem;
    }
}

.home-bg-custom {
    background: black url('../assets/city-nancy-bourque.jpg');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}


.card {
    background-color: $body-bg;
}



@media screen and (min-width: 48em){
    .navigation-top {
        top: 0;
        left: 0;
        position: fixed;
        right: 0;
        width: 100%;
        max-width: 100%;
        z-index: 3;
        background-color: $body-bg;
        vertical-align: bottom;
    }
}


@media screen and (min-width: 48em){
    .custom-front-page {
        height: 1200px;
        height: 100vh;
        max-height: 100%;
        overflow: hidden;
    }
}


.subhead {
    display: inline-block;
    align-self: flex-end;
}

.bd-text{
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
}